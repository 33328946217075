<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="main-content">
				<div class="header bg-white border-bottom">
					<div class="container-fluid">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Banner Overview</h6>
									<div
										class="d-flex justify-content-between align-items-center"
										>
										<h2 class="">{{ banner?.name ?? 'N/A' }}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<router-link
						to="/games&campaigns/banner"
						class="btn btn-light mb-4 btn-sm"
						>
						Go back
					</router-link>
					<div class="row">
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-body p-2">
									<div>
										<div style="padding: 1rem 1.5rem">
											<h4>Banner Information</h4>
										</div>
										<div class="list-group list-group-flush mb-4">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase">Title</span>
													</div>
													<div class="col-auto">
														<span class="">{{ banner?.name ?? 'N/A' }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Date created</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ banner.created_date }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Status</span
															>
													</div>
													<div class="col-auto">
														{{ banner.status ? 'Enabled' : 'Disabled' }}
													</div>
												</div>
											</div>
											<div
												class="list-group-item"
												v-if="banner.action_type === 'open_link'"
												>
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Embed Link</span
															>
													</div>
													<div class="col-auto">
														<div
															class="d-flex justify-content-end align-items-end flex-column"
															>
															<h5 class="pb-0 mb-0">
																{{
																	banner.action_type === 'open_link'
																		? 'YES'
																		: 'NO'
																}}
															</h5>
															<p class="pt-0 mt-0">
																{{ banner?.action_value ?? 'N/A' }}
															</p>
														</div>
													</div>
												</div>
											</div>

											<div
												class="list-group-item"
												v-if="banner.action_type === 'open_carousel'"
												>
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>CAROUSELS</span
															>
													</div>
													<div class="col-auto">
														<span>{{
															banner?.action_value ? 'YES' : 'NO'
														}}</span>
													</div>
												</div>
											</div>

											<div
												class="list-group-item"
												v-if="banner.action_type === 'open_route'"
												>
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase">Route</span>
													</div>
													<div class="col-auto">
														<span>{{ banner?.action_value ?? 'N/A' }}</span>
													</div>
												</div>
											</div>

											<div
												class="list-group-item"
												v-if="banner.action_type === 'open_route_discovery'"
												>
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Discovery page</span
															>
													</div>
													<div class="col-auto">
														<span>YES</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 col-xl-6">
							<b-card class="card">
								<h4>Banner Image</h4>
								<img
									class="imageDimensions"
									:src="banner.image_url"
									alt="banner_url"
									/>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ShowBanner',
  props: {
    bannerId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      banner: {},
      loading: false
    }
  },
  created () {
    this.loadBanner()

  },
  methods: {
    loadBanner () {
      this.loading = true
      this.axios
        .get(`/v1/dynamic-dashboard/banners/${this.bannerId}}`)
        .then((res) => {
          
          this.banner = this.refactorBannerData(res.data)
        })
        .catch(() => {
          this.$toastr.e('Something weent wrong', 'Error')
        })
        .finally(() => {
          this.loading = false
        })
    },
    refactorBannerData (payload) {
      const bannerObject = {
        name: payload.title,
        created_date: moment(payload.created_at).format('YYYY-MM-DD'),
        status: payload.status === 'active',
        end_date: moment(payload.end_date).format('YYYY-MM-DD'),
        resolved_dynamic_urls: payload.resolved_dynamic_urls,
        image_url: payload.image_url,
        dynamic_urls: payload.dynamic_urls,
        action_type: payload.action_type,
        action_value: payload.action_value,
        assets: payload.assets
      }

      return bannerObject
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}

.imageDimensions {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
